/*
 * @Author: weijiali
 * @Date: 2022-03-17 14:24:48
 * @LastEditors: weijiali
 * @LastEditTime: 2022-06-10 15:02:52
 */
import React from 'react';
import Loadable from 'react-loadable';

import { createRouter } from '@/utils/router';
import * as Cookie from '@/utils/cookie';
import PAGE from '@/constants/page';
import Loading from '@/components/Loading';

import App from './App';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    const { location, match, query } = nextState;
    const cookie = Cookie.getCookie();
    const isMatch = match && match.isExact;

    // spider.init();
    // 已登录
    if(cookie) {
      // index route
      if(isMatch) {
        history.replace(PAGE.HOME);
      }
      cb();
    } else {    // 未登录
      if(isMatch) {
        history.replace(PAGE.LOGIN);
      }
      cb();
    }
  },
  getChildRoutes: () => [
    {
      path: '/login',
      component: Loadable({
        loader: () => import('./login'),
        loading: () => <Loading />
      })
    },
    {
      path: '/mrx',
      component: Loadable({
        loader: () => import('./pages'),
        loading: () => <Loading />
      })
    },
    {
      component: Loadable({
        loader: () => import('./404'),
        loading: () => <Loading />
      })
    }
  ]
});
