/**
 *  Date    : 2021/12/09
 *  Author  : weiLin
 *  Declare : 默认菜单
 */
import Utils from '@/utils/utils';

// 图标
export const MENUS_ICON = {
  dc: 'https://file.5rs.me/oss/uploadfe/png/6973bce6a014449ad6735bee2775d2e2.png',
  book: 'https://file.5rs.me/oss/uploadfe/png/e9735e4f4892b042e23a46ed8b067073.png',
  model: 'https://file.5rs.me/oss/uploadfe/png/c06e88ac091f3c7248d13dae6ab35666.png',
  bookOrder: 'https://file.5rs.me/oss/uploadfe/png/7070317010691d73de02c6e5109afc4b.png',
  coupon: 'https://file.5rs.me/oss/uploadfe/png/8bf005a676874945a239099ac9846e68.png',
  activity: 'https://file.5rs.me/oss/uploadfe/png/7a8f959c44d7c0a92490010e6c296eab.png',
  banner: 'https://oss.5rs.me/oss/uploadfe/png/2b88aae0fb31bb9121f5270d1dcc3693.png',
  withdraw: 'https://oss.5rs.me/oss/uploadfe/png/2cfe6d54411185149a310fa515e00732.png',
  user: 'https://oss.5rs.me/oss/uploadfe/png/019c90cff133080bf241cc2b4f879709.png'
};

// 默认菜单
export const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    iconType: MENUS_ICON.book,
    id: 4,
    invalid: false,
    parentId: 0,
    name: '图书配置',
    path: '/mrx/bookConfig'
  },
  {
    iconType: MENUS_ICON.book,
    id: 5,
    invalid: true,
    parentId: 4,
    name: '图书配置',
    path: '/mrx/bookConfig/list'
  }
];

// 格式化配置路由
export default function setPermission(userInfo) {
  let [...routerPath] = PRIVATE_DOMAIN_PERMISSIONS;
  return Utils.unFlatMap(routerPath);
}
