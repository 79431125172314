/**
 *  Date    : 2019/11/7
 *  Author  : CastileMan
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import userApi from '@/api/userapi';
import Utils from '@/utils/utils';

import setPermission from './menus';
import * as Cookie from '@/utils/cookie';
import Message from '@/components/Message';
import menuData from './userData';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {
      permissionMenus: [],
      flatPermissionMenus: []
    },
    partyNameList: [],
    memberAccountList: {},
    roleList: [],
    labelList: [],
    memberBaseInfo: {},
    industryCaseInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/update/updateUserInfo',
        field: 'userInfo',
        promise: Promise.resolve({
          userName: "环球人物",
          avatar: "https://oss.5rs.me/oss/uploadfe/png/cba1bb603e69786b05f7861eec770c45.png?x-image-process=image/resize,m_fixed,h_100,w_100"
        })
          .then(data => {
            return Object.assign(menuData, data);
          })
          .then(data => {
            const { permissionVOList, partyId, ...userInfo } = data;
            //订单管理
            Cookie.saveCookie(`123adivder=${data.partyId}asddscjs`, 'userInfo');
            return Object.assign(data, {
              permissionMenus: setPermission(userInfo),
              flatPermissionMenus: Utils.flatMap(setPermission(userInfo))
            });

          })
      };
    },
    // 登录
    login(payload) {
      return {
        promise: userApi.login(payload)
      };
    },

    logout() {
      return {
        promise: new Promise(() => {
          // 直接退出系统
          Cookie.saveCookie('');  // 清除token
          Message.success('注销成功');
          window.location.href = '/login';
        })
      };
    }
  }
};
