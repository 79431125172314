export default {
  'systemId': 5,
  'isRemember': 0,
  'agentType': 3,
  'isNew': 0,
  'userName': '环球人物',
  'userId': 100002,
  'isSystem': '1',
  'inTest': [
    'groupAppCYP',
    'productResourceExport'
  ],
  'permissions': [
    {
      'id': 283,
      'permissionName': '现代纸书',
      'parentPermissionId': 0,
      'accessPath': '/adviser/book',
      'permissionType': '01',
      'children': [
        {
          'id': 286,
          'permissionName': '纸书',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1921,
              'permissionName': '全部现代纸书',
              'parentPermissionId': 286,
              'accessPath': '/adviser/book/list',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1919,
              'permissionName': '立项书',
              'parentPermissionId': 286,
              'accessPath': '/adviser/book/list/project',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': '提交给数传进行出版融合改造的书刊'
            },
            {
              'id': 1920,
              'permissionName': '非立项书',
              'parentPermissionId': 286,
              'accessPath': '/adviser/book/list/normal',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 405,
              'permissionName': '管理资料',
              'parentPermissionId': 286,
              'accessPath': '/adviser/book/list/id/manage',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0
            },
            {
              'id': 1293,
              'permissionName': '配置打包服务',
              'parentPermissionId': 286,
              'accessPath': '/adviser/book/list/packconfig',
              'permissionType': '01',
              'permissionCode': '5',
              'topsize': 100,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 284,
          'permissionName': '现代纸书',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/add',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': '1'
        },
        {
          'id': 1261,
          'permissionName': '二维码',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/qrcode/ticket',
          'permissionType': '01',
          'children': [
            {
              'id': 1911,
              'permissionName': '所有二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1262,
              'permissionName': '新增二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/add',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1922,
              'permissionName': 'RAYS二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/rays',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1923,
              'permissionName': '公众号二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/account',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1263,
              'permissionName': '配置二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/id/config',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1264,
              'permissionName': '修改二维码',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/id/update',
              'permissionType': '01',
              'permissionCode': '04',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1265,
              'permissionName': '二维码回收站',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/recyclebin',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 6,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2003,
              'permissionName': '配置H5页面',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/id/rays',
              'permissionType': '01',
              'permissionCode': '08',
              'topsize': 8,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7215,
              'permissionName': '配置落地页',
              'parentPermissionId': 1261,
              'accessPath': '/adviser/book/qrcode/ticket/customland',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 9,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '02',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1266,
          'permissionName': '智能推送',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/pushmsg',
          'permissionType': '01',
          'children': [
            {
              'id': 1267,
              'permissionName': '已配置消息应用',
              'parentPermissionId': 1266,
              'accessPath': '/adviser/book/pushmsg/configed',
              'permissionType': '01',
              'children': [
                {
                  'id': 1268,
                  'permissionName': '配置智能推送消息',
                  'parentPermissionId': 1267,
                  'accessPath': '/adviser/book/pushmsg/configed/id/config',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1269,
              'permissionName': '未配置消息应用',
              'parentPermissionId': 1266,
              'accessPath': '/adviser/book/pushmsg/unconfig',
              'permissionType': '01',
              'children': [
                {
                  'id': 1270,
                  'permissionName': '更多未配置消息应用',
                  'parentPermissionId': 1269,
                  'accessPath': '/adviser/book/pushmsg/unconfig/more',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1271,
                  'permissionName': '配置智能推送消息',
                  'parentPermissionId': 1269,
                  'accessPath': '/adviser/book/pushmsg/unconfig/id/config',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '05',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 2008,
          'permissionName': '创建立项书',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/addproject',
          'permissionType': '01',
          'permissionCode': '02',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 473,
          'permissionName': '书刊申诉',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/appeal',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 664,
          'permissionName': '书刊回收站',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/recyclebin',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1272,
          'permissionName': '制作现代纸书',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/modern',
          'permissionType': '01',
          'permissionCode': '05',
          'topsize': 7,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1395,
          'permissionName': '版权保护',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/genuine/intro',
          'permissionType': '01',
          'children': [
            {
              'id': 1396,
              'permissionName': '添加版权保护',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/step',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1918,
              'permissionName': '版权保护说明',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/legend',
              'permissionType': '01',
              'permissionCode': '1',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1917,
              'permissionName': '书籍列表',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro',
              'permissionType': '01',
              'permissionCode': '2',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1397,
              'permissionName': '修改版权信息',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/id/update',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7203,
              'permissionName': '修改一书一码版权信息',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/id/updatesingle',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1398,
              'permissionName': '数据统计',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/id/stats',
              'permissionType': '01',
              'permissionCode': '05',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7202,
              'permissionName': '添加一码版权保护',
              'parentPermissionId': 1395,
              'accessPath': '/adviser/book/genuine/intro/stepsingle',
              'permissionType': '01',
              'permissionCode': 'SINGLEERCODEGENUINE',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '03',
          'topsize': 8,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1902,
          'permissionName': '超级搜索',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/supersearch',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 9,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 2020,
          'permissionName': '防伪码',
          'parentPermissionId': 283,
          'accessPath': '/adviser/book/security',
          'permissionType': '01',
          'permissionCode': '',
          'topsize': 9,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'BOOK',
      'topsize': 1,
      'icon': 'ic-book',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 7232,
      'permissionName': '图书方案',
      'parentPermissionId': 0,
      'accessPath': '/adviser/app/create',
      'permissionType': '01',
      'permissionCode': 'APPPCREATE',
      'topsize': 2,
      'icon': 'ic-plan',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': '图书方案'
    },
    {
      'id': 131,
      'permissionName': '做书应用',
      'parentPermissionId': 0,
      'accessPath': '/adviser/app',
      'permissionType': '01',
      'children': [
        {
          'id': 1223,
          'permissionName': '应用中心',
          'parentPermissionId': 131,
          'accessPath': '/adviser/app/classify',
          'permissionType': '01',
          'children': [
            {
              'id': 7058,
              'permissionName': '应用中心',
              'parentPermissionId': 1223,
              'accessPath': '/adviser/app/classify',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1224,
              'permissionName': '创建我的应用',
              'parentPermissionId': 1223,
              'accessPath': '/adviser/app/store/add',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 133,
          'permissionName': '我的应用',
          'parentPermissionId': 131,
          'accessPath': '/adviser/app/myapp',
          'permissionType': '01',
          'children': [
            {
              'id': 7245,
              'permissionName': '我的应用',
              'parentPermissionId': 133,
              'accessPath': '/adviser/app/myapp',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 308,
              'permissionName': '管理应用',
              'parentPermissionId': 133,
              'accessPath': '/adviser/app/myapp/id/update',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 310,
              'permissionName': '应用详情',
              'parentPermissionId': 133,
              'accessPath': '/adviser/app/myapp/id/info',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 313,
              'permissionName': '更多应用',
              'parentPermissionId': 133,
              'accessPath': '/adviser/app/myapp/more',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2002,
              'permissionName': '推广详情',
              'parentPermissionId': 133,
              'accessPath': '/adviser/app/myapp/id/promoteDetails',
              'permissionType': '01',
              'permissionCode': '123456',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '02',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 591,
          'permissionName': '现代纸书案例分享',
          'parentPermissionId': 131,
          'accessPath': '/adviser/app/sharecase',
          'permissionType': '01',
          'children': [
            {
              'id': 1473,
              'permissionName': '全部案例',
              'parentPermissionId': 591,
              'accessPath': '/adviser/app/sharecase/caseList',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 592,
              'permissionName': '案例详情',
              'parentPermissionId': 591,
              'accessPath': '/adviser/app/sharecase/id/info',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '04',
          'topsize': 7,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1916,
          'permissionName': '应用统计',
          'parentPermissionId': 131,
          'accessPath': '/adviser/app/statistics',
          'permissionType': '01',
          'children': [
            {
              'id': 7246,
              'permissionName': '应用统计',
              'parentPermissionId': 1916,
              'accessPath': '/adviser/app/statistics',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '1234',
          'topsize': 8,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7208,
          'permissionName': '智能创作',
          'parentPermissionId': 131,
          'accessPath': '/adviser/app/creation',
          'permissionType': '01',
          'children': [
            {
              'id': 7209,
              'permissionName': '智能创作',
              'parentPermissionId': 7208,
              'accessPath': '/adviser/app/creation/creationInfo',
              'permissionType': '01',
              'children': [
                {
                  'id': 7214,
                  'permissionName': '创作',
                  'parentPermissionId': 7209,
                  'accessPath': '/adviser/app/creation/creationInfo/add',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7211,
              'permissionName': '创作记录',
              'parentPermissionId': 7208,
              'accessPath': '/adviser/app/creation/creationRecord',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '05',
          'topsize': 44,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'APP',
      'topsize': 3,
      'icon': 'ic-blocks',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 197,
      'permissionName': '读者运营',
      'parentPermissionId': 0,
      'accessPath': '/adviser/readers',
      'permissionType': '01',
      'children': [
        {
          'id': 1936,
          'permissionName': '运营工具',
          'parentPermissionId': 197,
          'accessPath': '/adviser/marketing',
          'permissionType': '01',
          'children': [
            {
              'id': 7194,
              'permissionName': '运费模板',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/freightTemplate',
              'permissionType': '01',
              'children': [
                {
                  'id': 7195,
                  'permissionName': '运费模版列表',
                  'parentPermissionId': 7194,
                  'accessPath': '/adviser/marketing/freightTemplate/list',
                  'permissionType': '01',
                  'permissionCode': 'YUNFEIMOBANLIEBIAO',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 7196,
                  'permissionName': '创建运费模版',
                  'parentPermissionId': 7194,
                  'accessPath': '/adviser/marketing/freightTemplate/add',
                  'permissionType': '01',
                  'permissionCode': 'CHUANGJIANYUNFEIMOBAN',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1937,
              'permissionName': '选择营销活动',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/type',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1938,
              'permissionName': '促销激励',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/promotion/list',
              'permissionType': '01',
              'children': [
                {
                  'id': 1939,
                  'permissionName': '我的营销激励',
                  'parentPermissionId': 1938,
                  'accessPath': '/adviser/marketing/promotion/list',
                  'permissionType': '01',
                  'permissionCode': 'WODECUXIAOJILI',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1940,
                  'permissionName': '新增营销激励',
                  'parentPermissionId': 1938,
                  'accessPath': '/adviser/marketing/promotion/add',
                  'permissionType': '01',
                  'permissionCode': 'XINZENGCUXIAOJILI',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1941,
                  'permissionName': '修改营销激励',
                  'parentPermissionId': 1938,
                  'accessPath': '/adviser/marketing/promotion/update/id',
                  'permissionType': '01',
                  'permissionCode': 'XIUGAICUXIAOJILI',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1942,
              'permissionName': '配置福利抽奖',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/banner',
              'permissionType': '01',
              'permissionCode': 'FULICHOUJIANG',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1943,
              'permissionName': '公众号',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/officialaccounts/list',
              'permissionType': '01',
              'children': [
                {
                  'id': 1944,
                  'permissionName': '创建公众号',
                  'parentPermissionId': 1943,
                  'accessPath': '/adviser/marketing/officialaccounts/add',
                  'permissionType': '01',
                  'permissionCode': 'officialaccountsadd',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1945,
                  'permissionName': '修改公众号',
                  'parentPermissionId': 1943,
                  'accessPath': '/adviser/marketing/officialaccounts/update',
                  'permissionType': '01',
                  'permissionCode': 'officialaccountsupdate',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1946,
                  'permissionName': '公众号列表',
                  'parentPermissionId': 1943,
                  'accessPath': '/adviser/marketing/officialaccounts/list',
                  'permissionType': '01',
                  'permissionCode': 'officialaccountslist',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': 'officialaccounts',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1947,
              'permissionName': '限时折扣',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/discount/list',
              'permissionType': '01',
              'children': [
                {
                  'id': 1948,
                  'permissionName': '创建限时折扣',
                  'parentPermissionId': 1947,
                  'accessPath': '/adviser/marketing/discount/add',
                  'permissionType': '01',
                  'permissionCode': 'XINZENGXIANSHIZHEKOU',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1950,
                  'permissionName': '我的限时折扣',
                  'parentPermissionId': 1947,
                  'accessPath': '/adviser/marketing/discount/list',
                  'permissionType': '01',
                  'permissionCode': 'WODEXIANSHIZHEKOU',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1951,
                  'permissionName': '查看限时折扣',
                  'parentPermissionId': 1947,
                  'accessPath': '/adviser/marketing/discount/browse',
                  'permissionType': '01',
                  'permissionCode': 'CHAKANXIANSHIZHEKOU',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1952,
                  'permissionName': '修改限时折扣',
                  'parentPermissionId': 1947,
                  'accessPath': '/adviser/marketing/discount/update',
                  'permissionType': '01',
                  'permissionCode': '04',
                  'topsize': 4,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': 'XIANSHIZHEKOU',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1953,
              'permissionName': '微信群裂变',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/groupfission/list',
              'permissionType': '01',
              'children': [
                {
                  'id': 1956,
                  'permissionName': '群活码管理',
                  'parentPermissionId': 1953,
                  'accessPath': '/adviser/marketing/groupfission/list',
                  'permissionType': '01',
                  'permissionCode': 'FISSONHOME',
                  'topsize': 0,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1957,
                  'permissionName': '创建微信群裂变',
                  'parentPermissionId': 1953,
                  'accessPath': '/adviser/marketing/groupfission/add',
                  'permissionType': '01',
                  'permissionCode': 'FISSONADD',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1958,
                  'permissionName': '管理群二维码',
                  'parentPermissionId': 1953,
                  'accessPath': '/adviser/marketing/groupfission/id/update',
                  'permissionType': '01',
                  'permissionCode': 'FISSONUPDATE',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': 'FISSION',
              'topsize': 6,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1954,
              'permissionName': '信息采集',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/preconditionconfig/list',
              'permissionType': '01',
              'children': [
                {
                  'id': 1959,
                  'permissionName': '创建信息采集',
                  'parentPermissionId': 1954,
                  'accessPath': '/adviser/marketing/preconditionconfig/add',
                  'permissionType': '01',
                  'permissionCode': 'PreconditionConfigAdd',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1960,
                  'permissionName': '信息采集列表',
                  'parentPermissionId': 1954,
                  'accessPath': '/adviser/marketing/preconditionconfig/list',
                  'permissionType': '01',
                  'permissionCode': 'PreconditionConfigList',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1961,
                  'permissionName': '修改信息采集',
                  'parentPermissionId': 1954,
                  'accessPath': '/adviser/marketing/preconditionconfig/update',
                  'permissionType': '01',
                  'permissionCode': 'PreconditionConfigUpdate',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': 'PreconditionConfig',
              'topsize': 7,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1955,
              'permissionName': '我的优惠券',
              'parentPermissionId': 1936,
              'accessPath': '/adviser/marketing/coupon',
              'permissionType': '01',
              'children': [
                {
                  'id': 1962,
                  'permissionName': '新增优惠券',
                  'parentPermissionId': 1955,
                  'accessPath': '/adviser/marketing/coupon/create',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1963,
                  'permissionName': '修改优惠券',
                  'parentPermissionId': 1955,
                  'accessPath': '/adviser/marketing/coupon/update/id',
                  'permissionType': '01',
                  'permissionCode': '02',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1964,
                  'permissionName': '查看优惠券',
                  'parentPermissionId': 1955,
                  'accessPath': '/adviser/marketing/coupon/info/id',
                  'permissionType': '01',
                  'permissionCode': '17',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '08',
              'topsize': 8,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 0,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1965,
          'permissionName': '社群管理',
          'parentPermissionId': 197,
          'accessPath': '/adviser/group',
          'permissionType': '01',
          'children': [
            {
              'id': 1966,
              'permissionName': '微信群',
              'parentPermissionId': 1965,
              'accessPath': '/adviser/group/code',
              'permissionType': '01',
              'children': [
                {
                  'id': 1976,
                  'permissionName': '新增关键词',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/addkeywords/id',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1977,
                  'permissionName': '配置关键词',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/configkeywords/id',
                  'permissionType': '01',
                  'permissionCode': '02',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1983,
                  'permissionName': '创建社群码',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/create',
                  'permissionType': '01',
                  'permissionCode': '03',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1980,
                  'permissionName': '修改社群码',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/update/id',
                  'permissionType': '01',
                  'permissionCode': '04',
                  'topsize': 4,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1979,
                  'permissionName': '群管理',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/manage/id',
                  'permissionType': '01',
                  'permissionCode': '05',
                  'topsize': 5,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1981,
                  'permissionName': '入群欢迎语',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/welcome/id',
                  'permissionType': '01',
                  'permissionCode': '06',
                  'topsize': 6,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1982,
                  'permissionName': '关键词回复',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/keywords/id',
                  'permissionType': '01',
                  'permissionCode': '07',
                  'topsize': 7,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1978,
                  'permissionName': '统计详情',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/analysis/id',
                  'permissionType': '01',
                  'permissionCode': '8',
                  'topsize': 8,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1984,
                  'permissionName': '群详情',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/detail/id',
                  'permissionType': '01',
                  'permissionCode': '9',
                  'topsize': 9,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1985,
                  'permissionName': '群公告',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/announcement/id',
                  'permissionType': '01',
                  'permissionCode': '10',
                  'topsize': 10,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1986,
                  'permissionName': '只创建社群码',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/createCode',
                  'permissionType': '01',
                  'permissionCode': '11',
                  'topsize': 11,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1987,
                  'permissionName': '配置资源服务',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/id/config',
                  'permissionType': '01',
                  'permissionCode': '12',
                  'topsize': 12,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1988,
                  'permissionName': '配置质检书刊',
                  'parentPermissionId': 1966,
                  'accessPath': '/adviser/group/code/qualityTest',
                  'permissionType': '01',
                  'permissionCode': '13',
                  'topsize': 13,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1967,
              'permissionName': '微信群发',
              'parentPermissionId': 1965,
              'accessPath': '/adviser/group/mass',
              'permissionType': '01',
              'children': [
                {
                  'id': 1974,
                  'permissionName': '微信群发',
                  'parentPermissionId': 1967,
                  'accessPath': '/adviser/group/mass/wechat',
                  'permissionType': '01',
                  'permissionCode': '1',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1975,
                  'permissionName': '个人号群发',
                  'parentPermissionId': 1967,
                  'accessPath': '/adviser/group/mass/personal',
                  'permissionType': '01',
                  'permissionCode': '02',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1968,
              'permissionName': '群打卡',
              'parentPermissionId': 1965,
              'accessPath': '/adviser/group/clock',
              'permissionType': '01',
              'children': [
                {
                  'id': 1971,
                  'permissionName': '创建微信群打卡',
                  'parentPermissionId': 1968,
                  'accessPath': '/adviser/group/clock/create',
                  'permissionType': '01',
                  'permissionCode': '09',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1972,
                  'permissionName': '修改微信群打卡',
                  'parentPermissionId': 1968,
                  'accessPath': '/adviser/group/clock/update/id',
                  'permissionType': '01',
                  'permissionCode': '02',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1973,
                  'permissionName': '微信群打卡详情',
                  'parentPermissionId': 1968,
                  'accessPath': '/adviser/group/clock/detail/id',
                  'permissionType': '01',
                  'permissionCode': '03',
                  'topsize': 3,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1969,
              'permissionName': '消息监控',
              'parentPermissionId': 1965,
              'accessPath': '/adviser/group/monitor',
              'permissionType': '01',
              'permissionCode': '04',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1970,
              'permissionName': '群运营',
              'parentPermissionId': 1965,
              'accessPath': '/adviser/group/operation',
              'permissionType': '01',
              'permissionCode': '05',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '02',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 200,
          'permissionName': '读者查询',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/search',
          'permissionType': '01',
          'children': [
            {
              'id': 7249,
              'permissionName': '读者查询',
              'parentPermissionId': 200,
              'accessPath': '/adviser/readers/search',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 333,
              'permissionName': '读者详情',
              'parentPermissionId': 200,
              'accessPath': '/adviser/readers/search/id/id/detail',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '0',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 500,
          'permissionName': '读者评价',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/comment',
          'permissionType': '01',
          'children': [
            {
              'id': 537,
              'permissionName': '服务评价',
              'parentPermissionId': 500,
              'accessPath': '/adviser/readers/comment/id/more',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 0,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0
            },
            {
              'id': 501,
              'permissionName': '评论详情',
              'parentPermissionId': 500,
              'accessPath': '/adviser/readers/comment/id/info',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0
            }
          ],
          'permissionCode': '01',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1989,
          'permissionName': '消息互动',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/message',
          'permissionType': '01',
          'children': [
            {
              'id': 1990,
              'permissionName': '公众号推送消息',
              'parentPermissionId': 1989,
              'accessPath': '/adviser/readers/message/messagepush',
              'permissionType': '01',
              'children': [
                {
                  'id': 1996,
                  'permissionName': '新增推送消息',
                  'parentPermissionId': 1990,
                  'accessPath': '/adviser/readers/message/messagepush/add',
                  'permissionType': '01',
                  'permissionCode': '1',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': 'MESSAGEPUSH',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1991,
              'permissionName': '读者评价',
              'parentPermissionId': 1989,
              'accessPath': '/adviser/readers/message/comment',
              'permissionType': '01',
              'children': [
                {
                  'id': 1997,
                  'permissionName': '服务评价',
                  'parentPermissionId': 1991,
                  'accessPath': '/adviser/readers/message/comment/id/more',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 1998,
                  'permissionName': '评论详情',
                  'parentPermissionId': 1991,
                  'accessPath': '/adviser/readers/message/comment/id/info',
                  'permissionType': '01',
                  'permissionCode': '02',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1992,
              'permissionName': '问题反馈',
              'parentPermissionId': 1989,
              'accessPath': '/adviser/readers/message/feedback',
              'permissionType': '01',
              'children': [
                {
                  'id': 2000,
                  'permissionName': '微信群列表',
                  'parentPermissionId': 1992,
                  'accessPath': '/adviser/readers/message/feedback/list',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1993,
              'permissionName': '读者消息',
              'parentPermissionId': 1989,
              'accessPath': '/adviser/readers/message/msg',
              'permissionType': '01',
              'children': [
                {
                  'id': 2001,
                  'permissionName': '消息回复',
                  'parentPermissionId': 1993,
                  'accessPath': '/adviser/readers/message/msg/id/reply',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '04',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': '1.读者扫描二维码，购买1对1辅导服务；\n2.辅导开始后，读者可在贵社服务号首页下方的对话框中输入自己的问题；\n3.编辑可在当前页面回复读者问题，读者会在服务号首页同步收到回复；\n4.编辑与读者可进行持续性交流互动，直至1对1辅导结束。'
            },
            {
              'id': 1994,
              'permissionName': '问答确认',
              'parentPermissionId': 1989,
              'accessPath': '/adviser/readers/message/question',
              'permissionType': '01',
              'permissionCode': '05',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '04',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1281,
          'permissionName': '问题反馈',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/feedback',
          'permissionType': '01',
          'children': [
            {
              'id': 1548,
              'permissionName': '微信群列表',
              'parentPermissionId': 1281,
              'accessPath': '/adviser/readers/feedback/list',
              'permissionType': '01',
              'permissionCode': '8',
              'topsize': 18,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 5,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1896,
          'permissionName': '公众号推送消息',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/messagepush',
          'permissionType': '01',
          'children': [
            {
              'id': 1897,
              'permissionName': '新增推送消息',
              'parentPermissionId': 1896,
              'accessPath': '/adviser/readers/messagepush/add',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 10,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'MESSAGEPUSH',
          'topsize': 30,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 496,
          'permissionName': '读者消息',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/msg',
          'permissionType': '01',
          'children': [
            {
              'id': 497,
              'permissionName': '消息回复',
              'parentPermissionId': 496,
              'accessPath': '/adviser/readers/msg/id/reply',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0
            }
          ],
          'permissionCode': '01',
          'topsize': 30,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': '1.读者扫描二维码，购买1对1辅导服务；\n2.辅导开始后，读者可在贵社服务号首页下方的对话框中输入自己的问题；\n3.编辑可在当前页面回复读者问题，读者会在服务号首页同步收到回复；\n4.编辑与读者可进行持续性交流互动，直至1对1辅导结束。'
        },
        {
          'id': 586,
          'permissionName': '问答确认',
          'parentPermissionId': 197,
          'accessPath': '/adviser/readers/question',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 40,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'READER',
      'topsize': 4,
      'icon': 'ic-readers',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 7066,
      'permissionName': '公众号列表',
      'parentPermissionId': 0,
      'accessPath': '/adviser/marketing/officialaccounts/list',
      'permissionType': '01',
      'permissionCode': 'officialaccountslist',
      'topsize': 5,
      'icon': '',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 107,
      'permissionName': '收益管理',
      'parentPermissionId': 0,
      'accessPath': '/adviser/rebate',
      'permissionType': '01',
      'children': [
        {
          'id': 251,
          'permissionName': '概览',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/index',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': -1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 276,
          'permissionName': '申请提现',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/withdraw',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 0,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 1,
          'superShow': 0
        },
        {
          'id': 130,
          'permissionName': '提现记录',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/record',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 436,
          'permissionName': '现代纸书收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/book',
          'permissionType': '01',
          'children': [
            {
              'id': 437,
              'permissionName': '二维码收益',
              'parentPermissionId': 436,
              'accessPath': '/adviser/rebate/book/id/info',
              'permissionType': '01',
              'permissionCode': '13',
              'topsize': 12,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 288,
          'permissionName': '作品收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/production',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 499,
          'permissionName': '赞赏收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/reward',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 544,
          'permissionName': '任务收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/task',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 5,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 1310,
          'permissionName': '正版授权费',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/genuinefee',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 6,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1513,
          'permissionName': '社群书正版授权费',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/groupgenuinefee',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 7,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7136,
          'permissionName': '会员作品收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/member',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 8,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1485,
          'permissionName': '社群书收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/bookGroup',
          'permissionType': '01',
          'children': [
            {
              'id': 1487,
              'permissionName': '社群码收益',
              'parentPermissionId': 1485,
              'accessPath': '/adviser/rebate/bookGroup/id/bookgroupinfo',
              'permissionType': '01',
              'permissionCode': '1',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '3',
          'topsize': 20,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1482,
          'permissionName': '入群收益',
          'parentPermissionId': 107,
          'accessPath': '/adviser/rebate/groupincome',
          'permissionType': '01',
          'permissionCode': '22',
          'topsize': 33,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'REBATE',
      'topsize': 6,
      'icon': 'ic-money',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 109,
      'permissionName': '数据管理',
      'parentPermissionId': 0,
      'accessPath': '/adviser/analysis',
      'permissionType': '01',
      'children': [
        {
          'id': 7193,
          'permissionName': '数据概览',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/overview',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 0,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 202,
          'permissionName': '读者分析',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/reader',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 1,
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 203,
          'permissionName': '图书分析',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/book',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 2,
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 204,
          'permissionName': '资源分析',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/resource',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 3,
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 439,
          'permissionName': '排行榜',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/rank',
          'permissionType': '01',
          'permissionCode': '1',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 715,
          'permissionName': '印码位置分析',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/qrcode',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 5,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1862,
          'permissionName': '版次分析',
          'parentPermissionId': 109,
          'accessPath': '/adviser/analysis/bookscatter',
          'permissionType': '01',
          'permissionCode': '1',
          'topsize': 6,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'ANALYSIS',
      'topsize': 6,
      'icon': 'ic-statistics',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 2028,
      'permissionName': '做书素材',
      'parentPermissionId': 0,
      'accessPath': '/adviser/resource',
      'permissionType': '01',
      'children': [
        {
          'id': 2029,
          'permissionName': '我的素材',
          'parentPermissionId': 2028,
          'accessPath': '/adviser/resource/search',
          'permissionType': '01',
          'children': [
            {
              'id': 2030,
              'permissionName': '资源管理',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/search',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2031,
              'permissionName': '文章管理',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/article',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2048,
              'permissionName': '新增资源',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/add',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2032,
              'permissionName': '题库管理',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/pretest',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2033,
              'permissionName': '单词库',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/words',
              'permissionType': '01',
              'children': [
                {
                  'id': 2045,
                  'permissionName': '单词维护',
                  'parentPermissionId': 2033,
                  'accessPath': '/adviser/resource/words/wordpreserve',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '04',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2047,
              'permissionName': '修改资源',
              'parentPermissionId': 2029,
              'accessPath': '/adviser/resource/id/update',
              'permissionType': '01',
              'permissionCode': '07',
              'topsize': 7,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 2034,
          'permissionName': '官方素材',
          'parentPermissionId': 2028,
          'accessPath': '/adviser/resource/tools',
          'permissionType': '01',
          'children': [
            {
              'id': 2035,
              'permissionName': '官方工具',
              'parentPermissionId': 2034,
              'accessPath': '/adviser/resource/tools',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2036,
              'permissionName': '系统单词库',
              'parentPermissionId': 2034,
              'accessPath': '/adviser/resource/sys',
              'permissionType': '01',
              'children': [
                {
                  'id': 2046,
                  'permissionName': '单词维护',
                  'parentPermissionId': 2036,
                  'accessPath': '/adviser/resource/words/wordpreserve',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2037,
              'permissionName': '第三方内容',
              'parentPermissionId': 2034,
              'accessPath': '/adviser/resource/third',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '02',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 2038,
          'permissionName': '超级作者',
          'parentPermissionId': 2028,
          'accessPath': '/adviser/product/home',
          'permissionType': '01',
          'children': [
            {
              'id': 2039,
              'permissionName': '作品分销首页',
              'parentPermissionId': 2038,
              'accessPath': '/adviser/product/home',
              'permissionType': '01',
              'permissionCode': 'PRODUCT_SALE_HOME',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2040,
              'permissionName': '更多作品',
              'parentPermissionId': 2038,
              'accessPath': '/adviser/product/more',
              'permissionType': '01',
              'permissionCode': 'MORE_PRODUCT',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2041,
              'permissionName': '作品详情',
              'parentPermissionId': 2038,
              'accessPath': '/adviser/product/detail',
              'permissionType': '01',
              'permissionCode': 'DETAIL_PRODUCT',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 2042,
              'permissionName': '分类详情',
              'parentPermissionId': 2038,
              'accessPath': '/adviser/product/type',
              'permissionType': '01',
              'permissionCode': 'TYPE_PRODUCT',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'product_sale',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'RESOURCE',
      'topsize': 6,
      'icon': 'ic-resource',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 1549,
      'permissionName': '超级作者',
      'parentPermissionId': 0,
      'accessPath': '/adviser/product',
      'permissionType': '01',
      'children': [
        {
          'id': 1552,
          'permissionName': '作品分销首页',
          'parentPermissionId': 1549,
          'accessPath': '/adviser/product/home',
          'permissionType': '01',
          'permissionCode': 'PRODUCT_SALE_HOME',
          'topsize': 0,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1554,
          'permissionName': '更多作品',
          'parentPermissionId': 1549,
          'accessPath': '/adviser/product/more',
          'permissionType': '01',
          'permissionCode': 'MORE_PRODUCT',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1555,
          'permissionName': '作品详情',
          'parentPermissionId': 1549,
          'accessPath': '/adviser/product/detail',
          'permissionType': '01',
          'permissionCode': 'DETAIL_PRODUCT',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1556,
          'permissionName': '分类详情',
          'parentPermissionId': 1549,
          'accessPath': '/adviser/products/types',
          'permissionType': '01',
          'permissionCode': 'TYPE_PRODUCT',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'product_sale',
      'topsize': 7,
      'icon': 'ic-product',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 1,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 1715,
      'permissionName': '尽调单',
      'parentPermissionId': 0,
      'accessPath': '/bookresearch',
      'permissionType': '01',
      'children': [
        {
          'id': 1913,
          'permissionName': '尽调单书刊二维码',
          'parentPermissionId': 1715,
          'accessPath': '/bookresearch/ercode',
          'permissionType': '01',
          'permissionCode': '099',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'JDD',
      'topsize': 9,
      'icon': '',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 7161,
      'permissionName': '企业微信',
      'parentPermissionId': 0,
      'accessPath': '/adviser/wechat',
      'permissionType': '01',
      'children': [
        {
          'id': 7162,
          'permissionName': '接入',
          'parentPermissionId': 7161,
          'accessPath': '/adviser/wechat/index',
          'permissionType': '01',
          'permissionCode': '',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7164,
          'permissionName': '拉新引流',
          'parentPermissionId': 7161,
          'accessPath': '/adviser/wechat/drainage',
          'permissionType': '01',
          'children': [
            {
              'id': 7165,
              'permissionName': '企业个人活码',
              'parentPermissionId': 7164,
              'accessPath': '/adviser/wechat/drainage/personalcode',
              'permissionType': '01',
              'children': [
                {
                  'id': 7185,
                  'permissionName': '新增企微个人活码',
                  'parentPermissionId': 7165,
                  'accessPath': '/adviser/wechat/drainage/personalcode/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7166,
              'permissionName': '企微社群活码',
              'parentPermissionId': 7164,
              'accessPath': '/adviser/wechat/drainage/communitycode',
              'permissionType': '01',
              'children': [
                {
                  'id': 7186,
                  'permissionName': '新增企微社群活码',
                  'parentPermissionId': 7166,
                  'accessPath': '/adviser/wechat/drainage/communitycode/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '1',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7167,
              'permissionName': '批量配置活码',
              'parentPermissionId': 7164,
              'accessPath': '/adviser/wechat/drainage/configurationcode',
              'permissionType': '01',
              'children': [
                {
                  'id': 7187,
                  'permissionName': '创建配置活码',
                  'parentPermissionId': 7167,
                  'accessPath': '/adviser/wechat/drainage/configurationcode/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 7188,
                  'permissionName': '修改配置活码',
                  'parentPermissionId': 7167,
                  'accessPath': '/adviser/wechat/drainage/configurationcode/update/id',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '1',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7168,
              'permissionName': '欢迎语',
              'parentPermissionId': 7164,
              'accessPath': '/adviser/wechat/drainage/greeting',
              'permissionType': '01',
              'children': [
                {
                  'id': 7197,
                  'permissionName': '设置企微社群欢迎语',
                  'parentPermissionId': 7168,
                  'accessPath': '/adviser/wechat/drainage/greeting/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '1',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7169,
              'permissionName': '群关键词',
              'parentPermissionId': 7164,
              'accessPath': '/adviser/wechat/drainage/antistop',
              'permissionType': '01',
              'permissionCode': '1',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7173,
          'permissionName': '读者触达',
          'parentPermissionId': 7161,
          'accessPath': '/adviser/wechat/reader',
          'permissionType': '01',
          'children': [
            {
              'id': 7192,
              'permissionName': '新增规则',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/rules',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7174,
              'permissionName': '企业好友群发',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/friend',
              'permissionType': '01',
              'children': [
                {
                  'id': 7190,
                  'permissionName': 'RAYS资源销售情况',
                  'parentPermissionId': 7174,
                  'accessPath': '/adviser/wechat/reader/friend/sellList',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 7189,
                  'permissionName': '新增企业好友群发',
                  'parentPermissionId': 7174,
                  'accessPath': '/adviser/wechat/reader/friend/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7175,
              'permissionName': '企微社群群发',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/association',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7177,
              'permissionName': '企微朋友圈',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/moments',
              'permissionType': '01',
              'children': [
                {
                  'id': 7191,
                  'permissionName': '新增企微朋友圈',
                  'parentPermissionId': 7177,
                  'accessPath': '/adviser/wechat/reader/moments/add',
                  'permissionType': '01',
                  'permissionCode': '',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 0,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7178,
              'permissionName': '个人SOP',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/personsop',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7179,
              'permissionName': '群SOP',
              'parentPermissionId': 7173,
              'accessPath': '/adviser/wechat/reader/groupsop',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7180,
          'permissionName': '读者管理',
          'parentPermissionId': 7161,
          'accessPath': '/adviser/wechat/management',
          'permissionType': '01',
          'children': [
            {
              'id': 7184,
              'permissionName': '用户画像',
              'parentPermissionId': 7180,
              'accessPath': '/adviser/wechat/management/readerdetail/id',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7181,
              'permissionName': '企业微信读者',
              'parentPermissionId': 7180,
              'accessPath': '/adviser/wechat/management/reader',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7182,
              'permissionName': '企业微信社群',
              'parentPermissionId': 7180,
              'accessPath': '/adviser/wechat/management/association',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'WECHAT',
      'topsize': 10,
      'icon': 'ic-a-bianzubeifen3x',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 110,
      'permissionName': '账户设置',
      'parentPermissionId': 0,
      'accessPath': '/adviser/setting',
      'permissionType': '01',
      'children': [
        {
          'id': 111,
          'permissionName': '基本资料',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/basic',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 665,
          'permissionName': '作者列表',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/merchant',
          'permissionType': '01',
          'permissionCode': '02',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 553,
          'permissionName': '成员账号',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/members',
          'permissionType': '01',
          'children': [
            {
              'id': 554,
              'permissionName': '新增成员账号',
              'parentPermissionId': 553,
              'accessPath': '/adviser/setting/members/add',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0
            },
            {
              'id': 555,
              'permissionName': '编辑成员账号',
              'parentPermissionId': 553,
              'accessPath': '/adviser/setting/members/id/update',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0
            }
          ],
          'permissionCode': '01',
          'topsize': 112,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 1,
          'superShow': 0
        },
        {
          'id': 161,
          'permissionName': '消息中心',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/message',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 113,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 187,
          'permissionName': '绑定微信 ',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/bindwechat',
          'permissionType': '01',
          'permissionCode': '1',
          'topsize': 114,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 542,
          'permissionName': '支付记录',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/payment',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 115,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 113,
          'permissionName': '相关设置',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/config',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 116,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 629,
          'permissionName': '常见问题',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/consultation',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 117,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 630,
          'permissionName': '常见问题',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/consolution',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 117,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0
        },
        {
          'id': 1477,
          'permissionName': '常见问题解答',
          'parentPermissionId': 110,
          'accessPath': '/adviser/setting/solution',
          'permissionType': '01',
          'permissionCode': '02',
          'topsize': 118,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'SETTING',
      'topsize': 109,
      'icon': 'ic-setting',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 358,
      'permissionName': '作者系统',
      'parentPermissionId': 0,
      'accessPath': '/merchant/resource/search/list',
      'permissionType': '01',
      'permissionCode': 'PRODUCT',
      'topsize': 120,
      'icon': 'ic-tiaozhuan',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 1389,
      'permissionName': '社群书',
      'parentPermissionId': 0,
      'accessPath': '/adviser/group',
      'permissionType': '01',
      'children': [
        {
          'id': 1390,
          'permissionName': '微信群',
          'parentPermissionId': 1389,
          'accessPath': '/adviser/group/code',
          'permissionType': '01',
          'children': [
            {
              'id': 1613,
              'permissionName': '配置关键词',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/configkeywords/id',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1392,
              'permissionName': '创建社群码',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/create',
              'permissionType': '01',
              'permissionCode': '05',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1612,
              'permissionName': '新增关键词',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/addkeywords/id',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1393,
              'permissionName': '修改社群码',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/update/id',
              'permissionType': '01',
              'permissionCode': '06',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1409,
              'permissionName': '群管理',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/manage/id',
              'permissionType': '01',
              'permissionCode': '07',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1410,
              'permissionName': '入群欢迎语',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/welcome/id',
              'permissionType': '01',
              'permissionCode': '08',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1412,
              'permissionName': '统计详情',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/analysis/id',
              'permissionType': '01',
              'permissionCode': '08',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1411,
              'permissionName': '关键词回复',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/keywords/id',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1423,
              'permissionName': '群公告',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/announcement/id',
              'permissionType': '01',
              'permissionCode': '11',
              'topsize': 9,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1413,
              'permissionName': '群详情',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/detail/id',
              'permissionType': '01',
              'permissionCode': '10',
              'topsize': 9,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1427,
              'permissionName': '只创建社群码',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/createCode',
              'permissionType': '01',
              'permissionCode': '08',
              'topsize': 9,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1518,
              'permissionName': '配置资源服务',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/id/config',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 10,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1764,
              'permissionName': '配置质检书刊',
              'parentPermissionId': 1390,
              'accessPath': '/adviser/group/code/qualityTest',
              'permissionType': '01',
              'permissionCode': '10',
              'topsize': 11,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '05',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1576,
          'permissionName': '微信群发',
          'parentPermissionId': 1389,
          'accessPath': '/adviser/group/mass',
          'permissionType': '01',
          'children': [
            {
              'id': 1577,
              'permissionName': '微信群发',
              'parentPermissionId': 1576,
              'accessPath': '/adviser/group/mass/wechat',
              'permissionType': '01',
              'permissionCode': '1',
              'topsize': 0,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': 'qweqw'
            },
            {
              'id': 1578,
              'permissionName': '个人号群发',
              'parentPermissionId': 1576,
              'accessPath': '/adviser/group/mass/personal',
              'permissionType': '01',
              'permissionCode': '2',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '02',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1419,
          'permissionName': '群打卡',
          'parentPermissionId': 1389,
          'accessPath': '/adviser/group/clock',
          'permissionType': '01',
          'children': [
            {
              'id': 1420,
              'permissionName': '创建微信群打卡',
              'parentPermissionId': 1419,
              'accessPath': '/adviser/group/clock/create',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1421,
              'permissionName': '修改微信群打卡',
              'parentPermissionId': 1419,
              'accessPath': '/adviser/group/clock/update/id',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1422,
              'permissionName': '微信群打卡详情',
              'parentPermissionId': 1419,
              'accessPath': '/adviser/group/clock/detail/id',
              'permissionType': '01',
              'permissionCode': '09',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '07',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1426,
          'permissionName': '消息监控',
          'parentPermissionId': 1389,
          'accessPath': '/adviser/group/monitor',
          'permissionType': '01',
          'permissionCode': '04',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1469,
          'permissionName': '群运营',
          'parentPermissionId': 1389,
          'accessPath': '/adviser/group/operation',
          'permissionType': '01',
          'permissionCode': '1',
          'topsize': 5,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': '03',
      'topsize': 131,
      'icon': 'ic-shequnshu',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 480,
      'permissionName': '消息配置',
      'parentPermissionId': 0,
      'accessPath': '/adviser/qrcode/leader',
      'permissionType': '01',
      'children': [
        {
          'id': 481,
          'permissionName': '我的二维码',
          'parentPermissionId': 480,
          'accessPath': '/adviser/qrcode/ticket',
          'permissionType': '01',
          'children': [
            {
              'id': 482,
              'permissionName': '修改二维码',
              'parentPermissionId': 481,
              'accessPath': '/adviser/qrcode/ticket/id/update',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 483,
              'permissionName': '配置二维码',
              'parentPermissionId': 481,
              'accessPath': '/adviser/qrcode/ticket/id/config',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 585,
              'permissionName': '二维码回收站',
              'parentPermissionId': 481,
              'accessPath': '/adviser/qrcode/recyclebin',
              'permissionType': '01',
              'permissionCode': '01',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '01',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 485,
          'permissionName': '智能推送',
          'parentPermissionId': 480,
          'accessPath': '/adviser/qrcode/msgpush/configed',
          'permissionType': '01',
          'children': [
            {
              'id': 487,
              'permissionName': '已配置消息应用',
              'parentPermissionId': 485,
              'accessPath': '/adviser/qrcode/msgpush/configed',
              'permissionType': '01',
              'children': [
                {
                  'id': 489,
                  'permissionName': '配置智能推送消息',
                  'parentPermissionId': 487,
                  'accessPath': '/adviser/qrcode/msgpush/configed/id/config',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 1,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '01',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 488,
              'permissionName': '未配置消息应用',
              'parentPermissionId': 485,
              'accessPath': '/adviser/qrcode/msgpush/unconfig',
              'permissionType': '01',
              'children': [
                {
                  'id': 490,
                  'permissionName': '更多未配置消息应用',
                  'parentPermissionId': 488,
                  'accessPath': '/adviser/qrcode/msgpush/unconfig/more',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 1,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 1,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                },
                {
                  'id': 491,
                  'permissionName': '配置智能推送消息',
                  'parentPermissionId': 488,
                  'accessPath': '/adviser/qrcode/msgpush/unconfig/id/config',
                  'permissionType': '01',
                  'permissionCode': '01',
                  'topsize': 2,
                  'icon': '',
                  'systemCode': 'adviser',
                  'isShow': 1,
                  'isMember': 0,
                  'superShow': 0,
                  'menuExplain': ''
                }
              ],
              'permissionCode': '01',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0
            }
          ],
          'permissionCode': '01',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 628,
          'permissionName': '模板做书',
          'parentPermissionId': 480,
          'accessPath': '/adviser/qrcode/template',
          'permissionType': '01',
          'permissionCode': '01',
          'topsize': 55,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0
        }
      ],
      'permissionCode': 'MESSAGE',
      'topsize': 140,
      'icon': 'ic-erweima',
      'systemCode': 'adviser',
      'isShow': 0,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    },
    {
      'id': 1186,
      'permissionName': '运营工具',
      'parentPermissionId': 0,
      'accessPath': '/adviser/marketing',
      'permissionType': '01',
      'children': [
        {
          'id': 1326,
          'permissionName': '选择营销活动',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/type',
          'permissionType': '01',
          'children': [
            {
              'id': 7248,
              'permissionName': '选择营销活动',
              'parentPermissionId': 1326,
              'accessPath': '/adviser/marketing/type',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'XUNAZEHUODONG',
          'topsize': 1,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 1,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1364,
          'permissionName': '配置福利抽奖',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/banner',
          'permissionType': '01',
          'permissionCode': 'FULICHOUJIANG',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1327,
          'permissionName': '促销激励',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/promotion/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1328,
              'permissionName': '我的营销激励',
              'parentPermissionId': 1327,
              'accessPath': '/adviser/marketing/promotion/list',
              'permissionType': '01',
              'permissionCode': 'WODECUXIAOJILI',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1329,
              'permissionName': '新增营销激励',
              'parentPermissionId': 1327,
              'accessPath': '/adviser/marketing/promotion/add',
              'permissionType': '01',
              'permissionCode': 'XINZENGCUXIAOJILI',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1330,
              'permissionName': '修改营销激励',
              'parentPermissionId': 1327,
              'accessPath': '/adviser/marketing/promotion/update/id',
              'permissionType': '01',
              'permissionCode': 'XIUGAICUXIAOJILI',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'CUXIAOJILI',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1880,
          'permissionName': '公众号',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/officialaccounts/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1882,
              'permissionName': '创建公众号',
              'parentPermissionId': 1880,
              'accessPath': '/adviser/marketing/officialaccounts/add',
              'permissionType': '01',
              'permissionCode': 'officialaccountsadd',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1883,
              'permissionName': '修改公众号',
              'parentPermissionId': 1880,
              'accessPath': '/adviser/marketing/officialaccounts/update',
              'permissionType': '01',
              'permissionCode': 'officialaccountsupdate',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1885,
              'permissionName': '公众号列表',
              'parentPermissionId': 1880,
              'accessPath': '/adviser/marketing/officialaccounts/list',
              'permissionType': '01',
              'permissionCode': 'officialaccountslist',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'officialaccounts',
          'topsize': 2,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1331,
          'permissionName': '限时折扣',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/discount/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1496,
              'permissionName': '我的限时折扣',
              'parentPermissionId': 1331,
              'accessPath': '/adviser/marketing/discount/list',
              'permissionType': '01',
              'permissionCode': 'WODEXIANSHIZHEKOU',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1333,
              'permissionName': '创建限时折扣',
              'parentPermissionId': 1331,
              'accessPath': '/adviser/marketing/discount/add',
              'permissionType': '01',
              'permissionCode': 'XINZENGXIANSHIZHEKOU',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1334,
              'permissionName': '查看限时折扣',
              'parentPermissionId': 1331,
              'accessPath': '/adviser/marketing/discount/browse',
              'permissionType': '01',
              'permissionCode': 'CHAKANXIANSHIZHEKOU',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1335,
              'permissionName': '修改限时折扣',
              'parentPermissionId': 1331,
              'accessPath': '/adviser/marketing/discount/update',
              'permissionType': '01',
              'permissionCode': 'XIUGAIXIANSHIZHEKOU',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'XIANSHIZHEKOU',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7104,
          'permissionName': '组合应用',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/compose/list',
          'permissionType': '01',
          'children': [
            {
              'id': 7106,
              'permissionName': '修改组合应用',
              'parentPermissionId': 7104,
              'accessPath': '/adviser/marketing/compose/update',
              'permissionType': '01',
              'permissionCode': '02',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7105,
              'permissionName': '新增组合',
              'parentPermissionId': 7104,
              'accessPath': '/adviser/marketing/compose/add',
              'permissionType': '01',
              'permissionCode': '03',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '3',
          'topsize': 3,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1886,
          'permissionName': '信息采集',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/preconditionconfig/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1887,
              'permissionName': '创建信息采集',
              'parentPermissionId': 1886,
              'accessPath': '/adviser/marketing/preconditionconfig/add',
              'permissionType': '01',
              'permissionCode': 'PreconditionConfigAdd',
              'topsize': 4,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1888,
              'permissionName': '信息采集列表',
              'parentPermissionId': 1886,
              'accessPath': '/adviser/marketing/preconditionconfig/list',
              'permissionType': '01',
              'permissionCode': 'PreconditionConfigList',
              'topsize': 5,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1889,
              'permissionName': '修改信息采集',
              'parentPermissionId': 1886,
              'accessPath': '/adviser/marketing/preconditionconfig/update',
              'permissionType': '01',
              'permissionCode': 'PreconditionConfigUpdate',
              'topsize': 6,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'PreconditionConfig',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1349,
          'permissionName': '微信群裂变',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/groupfission/list',
          'permissionType': '01',
          'children': [
            {
              'id': 1350,
              'permissionName': '群活码管理',
              'parentPermissionId': 1349,
              'accessPath': '/adviser/marketing/groupfission/list',
              'permissionType': '01',
              'permissionCode': 'FISSONHOME',
              'topsize': 0,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 1,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1351,
              'permissionName': '创建微信群裂变',
              'parentPermissionId': 1349,
              'accessPath': '/adviser/marketing/groupfission/add',
              'permissionType': '01',
              'permissionCode': 'FISSONADD',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1352,
              'permissionName': '管理群二维码',
              'parentPermissionId': 1349,
              'accessPath': '/adviser/marketing/groupfission/id/update',
              'permissionType': '01',
              'permissionCode': 'FISSONUPDATE',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'FISSION',
          'topsize': 4,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 1458,
          'permissionName': '我的优惠券',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/coupon',
          'permissionType': '01',
          'children': [
            {
              'id': 1461,
              'permissionName': '新增优惠券',
              'parentPermissionId': 1458,
              'accessPath': '/adviser/marketing/coupon/create',
              'permissionType': '01',
              'permissionCode': '10',
              'topsize': 1,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1464,
              'permissionName': '修改优惠券',
              'parentPermissionId': 1458,
              'accessPath': '/adviser/marketing/coupon/update/id',
              'permissionType': '01',
              'permissionCode': '16',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 1465,
              'permissionName': '查看优惠券',
              'parentPermissionId': 1458,
              'accessPath': '/adviser/marketing/coupon/info/id',
              'permissionType': '01',
              'permissionCode': '17',
              'topsize': 3,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': '15',
          'topsize': 6,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7055,
          'permissionName': '裂变海报',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/fissionposter/list',
          'permissionType': '01',
          'children': [
            {
              'id': 7056,
              'permissionName': '裂变海报列表',
              'parentPermissionId': 7055,
              'accessPath': '/adviser/marketing/fissionposter/list',
              'permissionType': '01',
              'permissionCode': 'posterlist',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7059,
              'permissionName': '新增裂变海报',
              'parentPermissionId': 7055,
              'accessPath': '/adviser/marketing/fissionposter/add',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 2,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7101,
              'permissionName': '已关联应用',
              'parentPermissionId': 7055,
              'accessPath': '/adviser/marketing/fissionposter/relateapp',
              'permissionType': '01',
              'permissionCode': 'relateapp',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'FissionPoster',
          'topsize': 99,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7170,
          'permissionName': '佣金推广优惠券',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/commissioncoupon/list',
          'permissionType': '01',
          'children': [
            {
              'id': 7171,
              'permissionName': '新增佣金推广优惠券',
              'parentPermissionId': 7170,
              'accessPath': '/adviser/marketing/commissioncoupon/add',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7172,
              'permissionName': '佣金推广优惠券详情',
              'parentPermissionId': 7170,
              'accessPath': '/adviser/marketing/commissioncoupon/detail',
              'permissionType': '01',
              'permissionCode': '',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'COMMISSIONCOUPONLIST',
          'topsize': 99,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 7158,
          'permissionName': '优惠券',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/couponnew/list',
          'permissionType': '01',
          'children': [
            {
              'id': 7159,
              'permissionName': '设置优惠券',
              'parentPermissionId': 7158,
              'accessPath': '/adviser/marketing/couponnew/add',
              'permissionType': '01',
              'permissionCode': 'couponnew',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            },
            {
              'id': 7160,
              'permissionName': '优惠券详情',
              'parentPermissionId': 7158,
              'accessPath': '/adviser/marketing/couponnew/detail',
              'permissionType': '01',
              'permissionCode': 'couponnewdetail',
              'topsize': 99,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 1,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'COUPONNEW',
          'topsize': 99,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 1,
          'superShow': 0,
          'menuExplain': ''
        },
        {
          'id': 2049,
          'permissionName': '防盗链',
          'parentPermissionId': 1186,
          'accessPath': '/adviser/marketing/safey/list',
          'permissionType': '01',
          'children': [
            {
              'id': 2051,
              'permissionName': '防盗链设置',
              'parentPermissionId': 2049,
              'accessPath': '/adviser/marketing/safey/add',
              'permissionType': '01',
              'permissionCode': 'SAFEYADD',
              'topsize': 98,
              'icon': '',
              'systemCode': 'adviser',
              'isShow': 0,
              'isMember': 0,
              'superShow': 0,
              'menuExplain': ''
            }
          ],
          'permissionCode': 'SAFEY',
          'topsize': 99,
          'icon': '',
          'systemCode': 'adviser',
          'isShow': 0,
          'isMember': 0,
          'superShow': 0,
          'menuExplain': ''
        }
      ],
      'permissionCode': 'CUXIAOJILI',
      'topsize': 160,
      'icon': 'ic-cuxiaojili',
      'systemCode': 'adviser',
      'isShow': 1,
      'isMember': 0,
      'superShow': 0,
      'menuExplain': ''
    }
  ],
  'tenantId': 1404,
  'name': '三味书屋',
  'wechatUserId': 131598950,
  'isTiktokShopAuthorized': false,
  'partyId': 1404,
  'qrAutoConfig': 1,
  'thirdType': null
};
